"use client";
import { useEffect } from "react";

import * as Sentry from "@sentry/nextjs";
import Error from "next/error";

const GlobalError: React.FC<{ error: any }> = ({ error }) => {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html>
      <body>
        <Error statusCode={error?.statusCode} />
      </body>
    </html>
  );
};

export default GlobalError;
